<template>
  <div>
    <div v-if="pageLoad">
      <PageLoader></PageLoader>
    </div>
    <div v-else class="pending-holding">
      <h6 class="fp-text">Users/Linked & pending activation</h6>
      <div class="" v-if="ovadraftUser.length > 0">
        <div class="pf-item" v-for="(item, index) in ovadraftUser" :key="index">
          <div class="the-item">
            <div class="left-item">
              <p>{{ item.firstname }} {{ item.lastname }}</p>
              <p v-if="item.cardtype">{{ item.cardtype }} card</p>
              <p>{{ item.created_at | moment2 }}</p>
              <!-- <p>Customer's ID: {{ item.id }}</p> -->
            </div>
            <div class="right-item">
              <div class="text-right">
                <!-- data-toggle="modal"
                  data-target="#regdetails" -->
                <span
                  v-if="item.over_draft_status === null"
                  @click="openLinkCardModal(item)"
                  class="notlinked"
                  >Link card</span
                >
                <!-- css class => retry for unlinked user -->
                <!-- <span
                  v-if="item.over_draft_status != null"
                  @click="openLinkCardModal()"
                  class="retry"
                  >Upgrade card</span
                > -->
                <span
                  v-if="item.over_draft_status != null"
                  class="failedd"
                  :class="[
                    item.over_draft_status == 'linked' ? 'linked' : '',
                    item.over_draft_status == 'pending'
                      ? 'pending'
                      : 'notlinked'
                  ]"
                  >{{
                    item.over_draft_status != null
                      ? item.over_draft_status
                      : "Not Linked"
                  }}</span
                >
              </div>
              <p
                v-if="
                  item.over_draft_status != null &&
                    item.over_draft_status == 'linked' &&
                    item.cardtype != 'Platinum'
                "
                @click="openCardUpgradeModal(item.id)"
                class="retry2"
              >
                Upgrade card
              </p>
              <!-- <h6>{{ item.created_at | moment2 }}</h6> -->
            </div>
          </div>
        </div>
      </div>
      <div v-else class="no-user">
        <p>No user added yet</p>
      </div>
    </div>

    <!-- Modal Registration details starts -->
    <div
      class="modal fade"
      id="regdetails"
      tabindex="-1"
      aria-labelledby="regdetails"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered"
        style="margin-bottom: 50px;"
      >
        <div class="modal-content">
          <div class="modal-header" style="border-bottom: none;">
            <!-- <h5 class="modal-title title-modal" id="fundwallet">
              Fund Wallet -->
            <!-- </h5> -->
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="header-text text-left pl-3 pr-3">
            <h6>Customer registration details</h6>
          </div>
          <div class="modal-body reg-body">
            <div class="item-details">
              <p>Name</p>
              <p>John Doe</p>
            </div>
            <div class="item-details">
              <p>Reg. date</p>
              <p>Jan 10, 2024</p>
            </div>
            <div class="item-details">
              <p>Status</p>
              <p>Activation failed</p>
            </div>
            <div class="item-details">
              <p>Failure reason</p>
              <p>Identity verification failed, Phone number not confirmed</p>
            </div>

            <div class="item-details">
              <p style="font-weight: 600;">Details</p>
            </div>

            <div class="item-details">
              <p>Phone number</p>
              <p>Pending</p>
            </div>
            <div class="item-details">
              <p>Email address</p>
              <p>Not provided</p>
            </div>
          </div>
          <div class="modal-footer" style="border: none;">
            <button
              @click="openRegistrationModal()"
              type="button"
              class="btn btn-block retry-btn"
            >
              Retry
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal Registration details ends -->
    <new-user-modal></new-user-modal>
    <card-upgrade-modal></card-upgrade-modal>
  </div>
</template>

<script>
import { Service } from "../../store/service";
import moment from "moment";
import $ from "jquery";
const Api = new Service();
import PageLoader from "../PageLoader.vue";
import NewUserModal from "./NewUserModal.vue";
import CardUpgradeModal from "./CardUpgradeModal.vue";

export default {
  name: "PaddingLinkedRequests",
  components: {
    NewUserModal,
    CardUpgradeModal,
    PageLoader
  },
  data() {
    return {
      loader: false,
      pageLoad: false,
      ovadraftUser: ""
    };
  },
  mounted() {
    this.user = JSON.parse(localStorage.getItem("shelta-user"));
    this.getRegisteredUser();
  },
  beforeDestroy() {
    this.loadingState = ""; // save props data to itself's data and deal with it
  },
  created() {
    this.$root.$refs.usersCreated = this;
  },
  methods: {
    openLinkCardModal(item) {
      this.$root.$refs.linkcardmodal.openLinkCardModal2(item);
    },
    openRegistrationModal(propInfo) {
      $("#regdetails")
        .removeClass("fade")
        .modal("hide");
      this.$root.$refs.usermodal.openRegistrationModal(propInfo);
    },
    openCardUpgradeModal(cardDetail) {
      this.$root.$refs.cardmodal.opencardupgrade(cardDetail);
    },
    async getRegisteredUser() {
      this.pageLoad = true;
      try {
        await Api.getRequest(`fetchreferredoverdraftusers`)
          .then(res => {
            this.pageLoad = false;
            if (res.data.success) {
              this.ovadraftUser = res.data.over_draft_referrals;
            }
            if (res.data.error) {
              return this.$store.commit("setApiFailed", res.data.error);
            }
          })
          .catch(err => {
            this.pageLoad = false;
            console.log(err);
            return this.$store.commit("setApiFailed", "Error occured");
          });
      } finally {
        // console.log("close");
      }
    }
  },
  filters: {
    moment: function(date) {
      return moment(date).format("MMMM Do YYYY, h:mm:ss a");
    },
    moment2: function(date) {
      return moment(date).format("MMMM Do YYYY");
    }
  }
};
</script>

<style lang="scss" scoped>
@font-face {
  font-family: "Lato-Bold";
  src: local("Lato-Bold"),
    url("../../assets/fonts/Lato/Lato-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Lato";
  src: local("Lato-Regular"),
    url("../../assets/fonts/Lato/Lato-Regular.ttf") format("truetype");
}

$primary: #0033ea;
$secondary: #ffffff;
$yellow: #ffb100;
$tatiary: #a2b0e3;
$black: #000000;
$sheltaBlack: #262626;
$limeGreen: #3ec250;
$sheltaGrey: #555c74;
$offBlack: #2b3352;

.pending-holding {
  background: $secondary;
  padding: 24px 24px;
  height: 100vh;
  padding-bottom: 30px;
}

.fp-text {
  color: $sheltaGrey;
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  text-align: left;
  padding: 20px 0 19px 0;
}

.pf-item {
  border-radius: 4px;
  border: 1px solid #e2e8f0;
  padding: 16px;
  margin-bottom: 16px;
}
.the-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}
.left-item {
  p {
    color: $sheltaGrey;
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-bottom: 0;
    text-align: left;
  }
}
.retry {
  border-radius: 16px;
  background: #f2f4f7;
  padding: 4px 8px;
  color: #2b3352;
  text-align: center;
  font-family: Lato;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  cursor: pointer;
  text-transform: capitalize;
}
.retry2 {
  border-radius: 16px;
  background: #f2f4f7;
  padding: 4px 8px;
  color: #2b3352;
  text-align: center;
  font-family: Lato;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  cursor: pointer;
  text-transform: capitalize;
  margin-top: 5px;
  margin-bottom: 0;
}
.notlinked {
  border-radius: 16px;
  background: #ffaeab;
  color: #2b3352;
  text-align: center;
  font-family: Lato;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  padding: 4px 8px;
  margin-left: 16px;
  text-transform: capitalize;
  cursor: pointer;
}
.pending {
  border-radius: 16px;
  background: #ffb100;
  color: #2b3352;
  text-align: center;
  font-family: Lato;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  padding: 4px 8px;
  text-transform: capitalize;
}
.linked {
  border-radius: 16px;
  background: #2bc300;
  // color: #2b3352;
  color: $secondary;
  text-align: center;
  font-family: Lato;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  padding: 4px 8px;
  text-transform: capitalize;
}

.right-item {
  h6 {
    color: $sheltaGrey;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-bottom: 0;
    text-align: right;
    margin-top: 5px;
  }
}

.item-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  p {
    color: $sheltaGrey;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  p:first-child {
    text-align: left;
  }
  p:nth-child(2) {
    text-align: right;
  }
}
.retry-btn {
  border-radius: 6px;
  background: #ea0033;
  color: $secondary;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
  text-transform: capitalize;
  height: 48px;
  outline: 0;
  box-shadow: none;
  width: 100%;
}
.no-user {
  font-family: Lato;
  font-size: 18px;
  margin-top: 80px;
}

.no-user {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
</style>
