<template>
  <div>
    <!-- card upgrade options start -->
    <div
      class="modal fade"
      id="cardupgradestarts"
      tabindex="-1"
      aria-labelledby="cardupgradestarts"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered"
        style="margin-bottom: 50px;"
      >
        <div class="modal-content">
          <div class="modal-header" style="border-bottom: none;">
            <!-- <h5 class="modal-title title-modal" id="fundwallet">
              Fund Wallet -->
            <!-- </h5> -->
            <button
              @click="cardpin = ''"
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="header-text">
            <h6>Request Card Upgrade</h6>
          </div>
          <div class="modal-body reg-body">
            <form>
              <div class="form-group theform">
                <label
                  v-if="pinloader"
                  for="surname"
                  class="d-flex mt-1 label-tags loading-card-details"
                  >Loading card details</label
                >
                <label v-else for="cardpin" class="d-flex mt-1 label-tags"
                  >Card number/pin</label
                >
                <div class="input-group">
                  <input
                    class="form-control input-field"
                    id="cardpin"
                    name="cardpin"
                    placeholder="1234567890"
                    v-model="cardpin"
                  />
                  <div class="input-group-append">
                    <img
                      class="input-group-text"
                      id="basic-addon2"
                      style="background: none; cursor: pointer;"
                      src="../../assets/icons/scan-barcode.svg"
                      alt="scan"
                    />
                  </div>
                </div>
              </div>
              <!-- <div v-if="pindetailsshow" class="d-none">
                <div class="form-group theform">
                  <label for="holdername" class="d-flex mt-1 label-tags"
                    >Card holder's name</label
                  >
                  <input
                    disabled
                    class="form-control input-field"
                    id="holdername"
                    name="holdername"
                    placeholder="John Doe"
                  />
                </div>
                <div class="form-group theform">
                  <label for="name" class="d-flex mt-1 label-tags"
                    >Email address</label
                  >
                  <input
                    disabled
                    class="form-control input-field"
                    id="requestAmount"
                    name="requestAmount"
                  />
                </div>
              </div> -->
            </form>
          </div>
          <div class="modal-footer" style="border: none;">
            <!-- && !activateBtn || cardpin.length < 12 -->
            <button
              :disabled="pinloader || !activateBtn || cardpin.length < 12"
              type="button"
              class="btn btn-block continue-btn"
              @click="openCardDetailsModal()"
            >
              Proceed
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- card upgrade options ends -->

    <!-- card upgrade details starts -->
    <div
      class="modal fade"
      id="cardupgradedetails"
      tabindex="-1"
      aria-labelledby="cardupgradedetails"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered"
        style="margin-bottom: 50px;"
      >
        <div class="modal-content">
          <!-- <div class="modal-header" style="border-bottom: none;">
              <h5 class="modal-title title-modal" id="fundwallet">
                Upgrade Plan
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div> -->
          <div class="back-chevron" @click="openCardpinModal()">
            <i class="fa fa-chevron-left"></i>
          </div>
          <div class="modal-body reg-body" style="margin-bottom: 0px">
            <div class="top-detail">
              <h5 v-if="carddetails.cardtype">
                {{ carddetails.cardtype.cardname }}
              </h5>
              <h2 v-if="carddetails.cardtype">
                ₦{{ Number(carddetails.cardtype.amount).toLocaleString() }}
              </h2>
              <h6>This card requires a one-time purchase</h6>
            </div>
            <form>
              <div class="card-details-holder">
                <div class="card-details-item">
                  <h4>Minimum daily savings</h4>
                  <h4>₦500</h4>
                </div>
                <div class="card-details-item">
                  <h4>Transaction fee</h4>
                  <h4>5%</h4>
                </div>
                <div class="card-details-item">
                  <h4>Max. trans. limit</h4>
                  <h4 v-if="carddetails.cardtype">
                    ₦{{
                      Number(
                        carddetails.cardtype.transaction_limit
                      ).toLocaleString()
                    }}
                  </h4>
                </div>
                <!-- <div class="card-details-item">
                    <h4>Usage</h4>
                    <h4>Medical needs</h4>
                  </div> -->
                <!-- <div class="card-details-item">
                    <h4>Monthly card admin. fee</h4>
                    <h4>₦299</h4>
                  </div> -->
                <div class="card-details-item">
                  <h4>Insurance</h4>
                  <h4>1%</h4>
                </div>
              </div>
              <div
                v-if="carddetails.cardtype"
                class="modal-footer"
                style="border: none; margin-top: 24px;"
              >
                <button
                  v-if="carddetails.cardtype"
                  :disabled="upgradeCardloader"
                  type="button"
                  class="btn btn-block continue-btn"
                  @click="upgradeCard()"
                >
                  Select {{ carddetails.cardtype.cardname }} Card
                  <i
                    class="fa fa-spinner fa-spin ml-3 f-12 btn-loader"
                    v-if="upgradeCardloader"
                  ></i>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- card upgrade details ends -->
  </div>
</template>

<script>
import { Service } from "../../store/service";
import moment from "moment";
import $ from "jquery";
const Api = new Service();

export default {
  name: "CardUpgradeModal",
  components: {},
  data() {
    return {
      loader: false,
      pageLoad: false,
      upgradeCardloader: false,
      carddetails: "",
      pinloader: false,
      pindetailsshow: false,
      activateBtn: false,
      cardpin: "",
      carduserid: ""
    };
  },
  watch: {
    cardpin: function(newValue, oldValue) {
      if (newValue.length === 12) {
        this.getDetails();
      }
    }
  },
  mounted() {
    this.user = JSON.parse(localStorage.getItem("shelta-user"));
    // this.getDetails();
  },
  beforeDestroy() {
    this.loadingState = ""; // save props data to itself's data and deal with it
  },
  created() {
    this.$root.$refs.cardmodal = this;
    // this.$root.$refs.X = this;
  },
  methods: {
    // openCardUpgradeModal(cardDetail) {
    //   this.$root.$refs.cardmodal.opencardupgrade(cardDetail);
    // },
    opencardupgrade(userdetails) {
      this.carduserid = userdetails;
      $("#cardupgradestarts")
        .addClass("fade")
        .modal("show");
    },
    openCardpinModal() {
      $("#cardupgradedetails")
        .removeClass("fade")
        .modal("hide");
      $("#cardupgradestarts")
        .addClass("fade")
        .modal("show");
    },
    openCardDetailsModal() {
      $("#cardupgradestarts")
        .removeClass("fade")
        .modal("hide");
      $("#cardupgradedetails")
        .addClass("fade")
        .modal("show");
    },
    async getDetails() {
      this.pinloader = true;
      this.activateBtn = false;
      this.pindetailsshow = false;
      try {
        await Api.getRequest(`getcarddetailsbypin/${this.cardpin}`)
          .then(res => {
            this.pinloader = false;
            if (res.data.success) {
              this.activateBtn = true;
              this.pindetailsshow = true;
              this.carddetails = res.data.card;
              // return this.$store.commit("setApiSuccess", res.data.success);
            }
            if (res.data.error) {
              this.pinloader = false;
              this.pindetailsshow = false;
              this.activateBtn = false;
              return this.$store.commit("setApiFailed", res.data.error);
            }
          })
          .catch(err => {
            this.pinloader = false;
            this.pindetailsshow = false;
            this.activateBtn = false;
            console.log(err);
          });
      } finally {
        // console.log("close");
      }
    },
    async upgradeCard() {
      this.upgradeCardloader = true;
      try {
        await Api.postRequest(`vendorupgradecard`, {
          user_user_id: this.carduserid,
          card_pin: Number(this.cardpin)
        })
          .then(res => {
            this.upgradeCardloader = false;
            if (res.data.success) {
              $("#cardupgradedetails")
                .removeClass("fade")
                .modal("hide");
              return this.$store.commit("setApiSuccess", res.data.success);
            }
            if (res.data.error) {
              this.$store.commit("setApiFailed", res.data.error);
            }
          })
          .catch(err => {
            this.upgradeCardloader = false;
            console.log(err);
          });
      } finally {
        // console.log("close");
      }
    }
  },
  filters: {
    moment: function(date) {
      return moment(date).format("MMMM Do YYYY, h:mm:ss a");
    },
    moment2: function(date) {
      return moment(date).format("MMMM Do YYYY");
    }
  }
};
</script>

<style lang="scss" scoped>
$primary: #0033ea;
$secondary: #ffffff;
$yellow: #ffb100;
$tatiary: #a2b0e3;
$black: #000000;
$sheltaBlack: #262626;
$offBlack: #262626;
$limeGreen: #3ec250;
$sheltaGrey: #555c74;

@font-face {
  font-family: "Lato";
  src: local("Lato-Regular"),
    url("../../assets/fonts/Lato/Lato-Regular.ttf") format("truetype");
}

.back-chevron {
  margin: 24px 24px 16px;
  text-align: left;
  width: fit-content;
  i {
    background: #f9f8f8;
    border-radius: 30px;
    padding: 9px 10px;
    font-size: 12px;
    cursor: pointer;
  }
}
.top-detail {
  margin-bottom: 24px;
  h5,
  h2,
  h6 {
    font-family: Lato;
    font-style: normal;
    text-align: left;
    color: $sheltaGrey;
    margin-bottom: 4px;
  }
  h5 {
    font-size: 20px;
    line-height: 20px;
    font-weight: 600;
  }
  h2 {
    font-size: 32px;
    font-weight: 700;
    line-height: 150%;
  }
  h6 {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
  }
}
.card-details-holder {
  border-radius: 16px;
  background: #f9f8f8;
  padding: 0 16px;
}

.card-details-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 16px;
  padding-bottom: 16px;
  h4 {
    color: $sheltaGrey;
    text-align: right;
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 0;
  }
}

.title-modal {
  color: #2b3352;
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  text-transform: capitalize;
}

.payments-buttons {
  margin-bottom: 20px;

  button {
    border: 1px solid #e4e7ec;
    border-radius: 12px;
    height: 64px;
    width: 100%;
    background: transparent;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 20px;
  }
}

.check-click {
  width: 16px;
  height: 16px;
  border-radius: 10px;
  border: 1px solid #d0d5dd;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-left: 2px;
  padding-bottom: 2px;

  i {
    font-size: 10px;
    color: $secondary;
  }
}

.activePaymentMethod {
  background: #dde4fc !important;
  border: 1px solid $primary !important;
}

.activeCheckBox {
  background: $primary !important;
  border: 1px solid $primary !important;
}

.payment-select {
  margin-bottom: 40px;
  display: flex;
  img {
    margin-bottom: 8px;
    display: flex;
  }
  button {
    border-radius: 12px;
    border: 1px solid #e4e7ec;
    padding: 12px;
    box-shadow: none;
    outline: 0;
    width: 100%;
    h6 {
      color: $sheltaGrey;
      font-family: Lato;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      margin-bottom: 0;
      text-align: left;
    }
    p {
      color: $sheltaGrey;
      font-family: Lato;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      margin-bottom: 0;
      text-align: left;
    }
    .reuse {
      font-weight: 400;
    }
  }
  button:first-child {
    margin-right: 16px;
  }
}
.active-select-option {
  border: 1px solid $primary !important;
}

.card-upgrade-approval-header {
  margin-bottom: 24px;
  h2,
  h5,
  h4,
  h6 {
    color: #2b3352;
    text-align: center;
    font-family: Lato;
    font-style: normal;
    margin-bottom: 0;
  }
  h5,
  h4 {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
  h2 {
    font-size: 32px;
    font-weight: 600;
    line-height: 125%;
  }
  h6 {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-transform: uppercase;
  }
}
.card-features-text {
  color: $sheltaGrey;
  text-align: right;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
  text-align: left;
}

.continue-btn {
  border-radius: 6px;
  background: $primary;
  color: $secondary;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
  text-transform: capitalize;
  height: 48px;
  outline: 0;
  box-shadow: none;
  width: 100%;
}

.theform {
  margin-bottom: 24px;
}
.form-check-input {
  width: 20px !important;
  height: 20px !important;
}

.header-text {
  padding-left: 15px;
  text-align: left;
  color: $offBlack;
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}

.input-field,
.select-option {
  height: 48px;
  border-radius: 6px;
  border: 1px solid #e7e7e7;
  color: #667085;
  outline: 0;
  box-shadow: none;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  // line-height: 150%;
}

// Request for payment starts
.label-tags {
  color: $offBlack;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  margin-bottom: 8px;
}
.loading-card-details {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
// Upgrade details ends

::-webkit-input-placeholder {
  /* Edge */
  color: #ccced5;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #ccced5;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

::placeholder {
  color: #ccced5;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
</style>
