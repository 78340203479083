var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.pageLoad)?_c('div',[_c('PageLoader')],1):_c('div',{staticClass:"pending-holding"},[_c('h6',{staticClass:"fp-text"},[_vm._v("Users/Linked & pending activation")]),(_vm.ovadraftUser.length > 0)?_c('div',{},_vm._l((_vm.ovadraftUser),function(item,index){return _c('div',{key:index,staticClass:"pf-item"},[_c('div',{staticClass:"the-item"},[_c('div',{staticClass:"left-item"},[_c('p',[_vm._v(_vm._s(item.firstname)+" "+_vm._s(item.lastname))]),(item.cardtype)?_c('p',[_vm._v(_vm._s(item.cardtype)+" card")]):_vm._e(),_c('p',[_vm._v(_vm._s(_vm._f("moment2")(item.created_at)))])]),_c('div',{staticClass:"right-item"},[_c('div',{staticClass:"text-right"},[(item.over_draft_status === null)?_c('span',{staticClass:"notlinked",on:{"click":function($event){return _vm.openLinkCardModal(item)}}},[_vm._v("Link card")]):_vm._e(),(item.over_draft_status != null)?_c('span',{staticClass:"failedd",class:[
                  item.over_draft_status == 'linked' ? 'linked' : '',
                  item.over_draft_status == 'pending'
                    ? 'pending'
                    : 'notlinked'
                ]},[_vm._v(_vm._s(item.over_draft_status != null ? item.over_draft_status : "Not Linked"))]):_vm._e()]),(
                item.over_draft_status != null &&
                  item.over_draft_status == 'linked' &&
                  item.cardtype != 'Platinum'
              )?_c('p',{staticClass:"retry2",on:{"click":function($event){return _vm.openCardUpgradeModal(item.id)}}},[_vm._v(" Upgrade card ")]):_vm._e()])])])}),0):_c('div',{staticClass:"no-user"},[_c('p',[_vm._v("No user added yet")])])]),_c('div',{staticClass:"modal fade",attrs:{"id":"regdetails","tabindex":"-1","aria-labelledby":"regdetails","aria-hidden":"true"}},[_c('div',{staticClass:"modal-dialog modal-dialog-centered",staticStyle:{"margin-bottom":"50px"}},[_c('div',{staticClass:"modal-content"},[_vm._m(0),_vm._m(1),_vm._m(2),_c('div',{staticClass:"modal-footer",staticStyle:{"border":"none"}},[_c('button',{staticClass:"btn btn-block retry-btn",attrs:{"type":"button"},on:{"click":function($event){return _vm.openRegistrationModal()}}},[_vm._v(" Retry ")])])])])]),_c('new-user-modal'),_c('card-upgrade-modal')],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal-header",staticStyle:{"border-bottom":"none"}},[_c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"header-text text-left pl-3 pr-3"},[_c('h6',[_vm._v("Customer registration details")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal-body reg-body"},[_c('div',{staticClass:"item-details"},[_c('p',[_vm._v("Name")]),_c('p',[_vm._v("John Doe")])]),_c('div',{staticClass:"item-details"},[_c('p',[_vm._v("Reg. date")]),_c('p',[_vm._v("Jan 10, 2024")])]),_c('div',{staticClass:"item-details"},[_c('p',[_vm._v("Status")]),_c('p',[_vm._v("Activation failed")])]),_c('div',{staticClass:"item-details"},[_c('p',[_vm._v("Failure reason")]),_c('p',[_vm._v("Identity verification failed, Phone number not confirmed")])]),_c('div',{staticClass:"item-details"},[_c('p',{staticStyle:{"font-weight":"600"}},[_vm._v("Details")])]),_c('div',{staticClass:"item-details"},[_c('p',[_vm._v("Phone number")]),_c('p',[_vm._v("Pending")])]),_c('div',{staticClass:"item-details"},[_c('p',[_vm._v("Email address")]),_c('p',[_vm._v("Not provided")])])])}]

export { render, staticRenderFns }